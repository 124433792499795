.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/shardana_compressa-2-min-1500h.jpg");
  background-position: center;
}
.home-text {
  color: var(--dl-color-gray-white);
  font-size: 5rem;
  max-width: 600px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-family: "Spectral";
  text-shadow: 4px 4px 19px rgba(0,0,0,0.6);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text03 {
  color: var(--dl-color-gray-white);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  text-shadow: 4px 4px 19px rgba(0,0,0,0.6);
  margin-bottom: var(--dl-space-space-threeunits);
  letter-spacing: 2px;
  text-transform: uppercase;
}
.home-link {
  display: contents;
}
.home-component01 {
  text-decoration: none;
}
.home-banner {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-text04 {
  color: #03509e;
  font-size: 3.4rem;
  text-align: center;
  font-family: "Merriweather";
  font-weight: 600;
  text-shadow: 1px 2px 5px rgba(0,0,0,0.23);
}
.home-text05 {
  font-size: 1.15rem;
  max-width: 600px;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: "Inter";
  font-weight: 300;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-navlink {
  display: contents;
}
.home-component02 {
  text-decoration: none;
}
.home-blog {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-container01 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-hero1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 300px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(0, 153, 255, 0.28);
}
.home-container02 {
  display: flex;
  margin-right: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-text14 {
  font-size: 3rem;
  max-width: 450px;
  font-family: "Merriweather";
  border-radius: var(--dl-radius-radius-radius4);
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: reverse;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-text18 {
  font-size: 1.4rem;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Merriweather";
  font-weight: 200;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-image {
  width: 482px;
  height: 337px;
  display: none;
  object-fit: contain;
}
.home-navlink1 {
  display: contents;
}
.home-component06 {
  text-decoration: none;
}
.home-image1 {
  width: 720px;
  height: 400px;
  object-fit: contain;
}
.home-banner1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-text21 {
  color: #03509e;
  font-size: 2.8rem;
  text-align: center;
  font-family: "Merriweather";
}
.home-testimonial {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-container03 {
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: stretch;
  justify-content: space-between;
}
.home-container04 {
  flex: 2;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-container05 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-container06 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-hero2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 300px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(0, 153, 255, 0.28);
}
.home-image2 {
  width: 597px;
  height: 429px;
  object-fit: contain;
  image-orientation: none;
}
.home-container07 {
  display: flex;
  margin-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-text22 {
  font-size: 3rem;
  max-width: 450px;
  align-self: stretch;
  text-align: right;
  font-family: "Merriweather";
  border-radius: var(--dl-radius-radius-radius4);
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: reverse;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-text26 {
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.home-navlink2 {
  display: contents;
}
.home-component10 {
  text-decoration: none;
}
.home-hero3 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 300px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container08 {
  display: flex;
  margin-right: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-text27 {
  color: #03509e;
  font-size: 3rem;
  max-width: 450px;
  font-family: "Merriweather";
  border-radius: var(--dl-radius-radius-radius4);
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: reverse;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-text34 {
  font-size: 1.4rem;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Merriweather";
  font-weight: 200;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-image3 {
  width: 751px;
  height: 457px;
  object-fit: contain;
}
.home-link1 {
  display: contents;
}
.home-container09 {
  flex: 0 0 auto;
  right: var(--dl-space-space-fourunits);
  width: var(--dl-size-size-small);
  bottom: var(--dl-space-space-fourunits);
  cursor: pointer;
  height: var(--dl-size-size-small);
  display: flex;
  position: fixed;
  align-self: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #03509e;
}
.home-icon {
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .home-text04 {
    font-size: 3rem;
  }
  .home-text05 {
    max-width: 100%;
  }
  .home-hero1 {
    flex-direction: column;
  }
  .home-container02 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-text14 {
    text-align: center;
  }
  .home-text18 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-text19 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-text21 {
    font-size: 3rem;
  }
  .home-container03 {
    flex-direction: column;
  }
  .home-container04 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-hero2 {
    flex-direction: column;
  }
  .home-container07 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text22 {
    text-align: center;
  }
  .home-text26 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-hero3 {
    flex-direction: column;
  }
  .home-container08 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-text27 {
    text-align: center;
  }
  .home-text34 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-text35 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .home-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text {
    font-size: 3.5rem;
  }
  .home-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text05 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-blog {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-container01 {
    flex: 0 0 auto;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text18 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text19 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-image {
    width: 80%;
    display: block;
  }
  .home-image1 {
    width: 80%;
    display: none;
  }
  .home-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text21 {
    font-family: Merriweather;
  }
  .home-container03 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-hero2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-image2 {
    width: 80%;
    height: 300px;
  }
  .home-container07 {
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-text26 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-hero3 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text34 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text35 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-image3 {
    width: 80%;
    height: 288px;
  }
  .home-container09 {
    right: 35px;
  }
}
@media(max-width: 479px) {
  .home-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-blog {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container02 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container03 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-hero2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container07 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-hero3 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container08 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
