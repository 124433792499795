.contatti-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.contatti-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.contatti-text {
  color: #03509e;
  font-size: 3.6rem;
  text-align: center;
  font-family: "Merriweather";
  font-weight: 200;
}
.contatti-text1 {
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.contatti-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.contatti-text5 {
  color: rgb(3, 80, 158);
  font-size: 2rem;
  text-align: center;
  font-family: "Merriweather";
  font-weight: 200;
  margin-bottom: var(--dl-space-space-twounits);
}
.contatti-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contatti-separator {
  width: 100px;
  height: 2px;
  background-color: var(--dl-color-gray-500);
}
.contatti-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contatti-container3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.contatti-component1 {
  text-decoration: none;
}
.contatti-component2 {
  text-decoration: none;
}
.contatti-component3 {
  text-decoration: none;
}
.contatti-component4 {
  text-decoration: none;
}
.contatti-link4 {
  display: contents;
}
.contatti-component5 {
  text-decoration: none;
}
.contatti-banner1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.contatti-text8 {
  color: rgb(3, 80, 158);
  font-size: 2.8rem;
  text-align: center;
  font-family: "Merriweather";
}
@media(max-width: 991px) {
  .contatti-text1 {
    max-width: 100%;
  }
  .contatti-features {
    align-items: center;
  }
  .contatti-container2 {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .contatti-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contatti-text1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .contatti-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contatti-text5 {
    text-align: center;
  }
  .contatti-container2 {
    flex-direction: column;
  }
  .contatti-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .contatti-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .contatti-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .contatti-container3 {
    grid-template-columns: 1fr;
  }
  .contatti-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
