.feature-card4-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.feature-card4-image {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.feature-card4-text {
  color: rgb(3, 80, 158);
  text-align: center;
  font-family: "Merriweather";
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card4-text1 {
  color: var(--dl-color-gray-700);
}
.feature-card4-root-class-name {
  width: 100%;
}




@media(max-width: 479px) {
  .feature-card4-text {
    font-style: normal;
    font-weight: 600;
  }
}
