.chi-siamo-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.chi-siamo-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.chi-siamo-text {
  color: rgb(3, 80, 158);
  font-size: 3rem;
  text-align: center;
  font-family: "Merriweather";
  font-weight: 200;
}
.chi-siamo-text01 {
  max-width: 700px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.chi-siamo-banner1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.chi-siamo-text09 {
  color: rgb(3, 80, 158);
  font-size: 3rem;
  text-align: center;
  font-family: "Merriweather";
  font-weight: 200;
}
@media(max-width: 991px) {
  .chi-siamo-text01 {
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .chi-siamo-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .chi-siamo-text01 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .chi-siamo-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .chi-siamo-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .chi-siamo-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
