.carousel-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.carousel-slide1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.carousel-image {
  width: 100%;
  height: auto;
  box-shadow: 16px 16px 0px 0px #03509e;
  max-height: 50vh;
  object-fit: cover;
}
.carousel-left-btn {
  top: 225px;
  flex: 0 0 auto;
  left: 3%;
  right: 0px;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-left-btn:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-right-btn {
  top: 225px;
  flex: 0 0 auto;
  right: 3%;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-right-btn:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon02 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-slide2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.carousel-image1 {
  width: 100%;
  height: auto;
  box-shadow: 16px 16px 0px 0px #03509e;
  max-height: 50vh;
  object-fit: cover;
}
.carousel-left-btn1 {
  top: 225px;
  flex: 0 0 auto;
  left: 3%;
  right: 0px;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-left-btn1:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon04 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-right-btn1 {
  top: 225px;
  flex: 0 0 auto;
  right: 3%;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-right-btn1:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon06 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-slide3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.carousel-image2 {
  width: 100%;
  height: auto;
  box-shadow: 16px 16px 0px 0px #03509e;
  max-height: 50vh;
  object-fit: cover;
}
.carousel-left-btn2 {
  top: 225px;
  flex: 0 0 auto;
  left: 3%;
  right: 0px;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-left-btn2:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon08 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-right-btn2 {
  top: 225px;
  flex: 0 0 auto;
  right: 3%;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-right-btn2:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon10 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-slide4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.carousel-image3 {
  width: 100%;
  height: auto;
  box-shadow: 16px 16px 0px 0px #03509e;
  max-height: 50vh;
  object-fit: cover;
}
.carousel-left-btn3 {
  top: 225px;
  flex: 0 0 auto;
  left: 3%;
  right: 0px;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-left-btn3:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon12 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-right-btn3 {
  top: 225px;
  flex: 0 0 auto;
  right: 3%;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-right-btn3:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon14 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-slide5 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.carousel-image4 {
  width: 100%;
  height: auto;
  box-shadow: 16px 16px 0px 0px #03509e;
  max-height: 50vh;
  object-fit: cover;
}
.carousel-left-btn4 {
  top: 225px;
  flex: 0 0 auto;
  left: 3%;
  right: 0px;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-left-btn4:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon16 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-right-btn4 {
  top: 225px;
  flex: 0 0 auto;
  right: 3%;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-right-btn4:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon18 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-slide6 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.carousel-image5 {
  width: 100%;
  height: auto;
  box-shadow: 16px 16px 0px 0px #03509e;
  max-height: 50vh;
  object-fit: cover;
}
.carousel-left-btn5 {
  top: 225px;
  flex: 0 0 auto;
  left: 3%;
  right: 0px;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-left-btn5:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon20 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-right-btn5 {
  top: 225px;
  flex: 0 0 auto;
  right: 3%;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-right-btn5:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon22 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-slide7 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.carousel-image6 {
  width: 100%;
  height: auto;
  box-shadow: 16px 16px 0px 0px #03509e;
  max-height: 50vh;
  object-fit: cover;
}
.carousel-left-btn6 {
  top: 225px;
  flex: 0 0 auto;
  left: 3%;
  right: 0px;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-left-btn6:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon24 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-right-btn6 {
  top: 225px;
  flex: 0 0 auto;
  right: 3%;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-right-btn6:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon26 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-slide8 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.carousel-image7 {
  width: 100%;
  height: auto;
  box-shadow: 16px 16px 0px 0px #03509e;
  max-height: 50vh;
  object-fit: cover;
}
.carousel-left-btn7 {
  top: 225px;
  flex: 0 0 auto;
  left: 3%;
  right: 0px;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-left-btn7:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon28 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-right-btn7 {
  top: 225px;
  flex: 0 0 auto;
  right: 3%;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-color: #03509e;
}
.carousel-right-btn7:hover {
  background-color: rgba(3, 80, 158, 0.75);
}
.carousel-icon30 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: center;
}
.carousel-root-class-name {
  margin-bottom: 100px;
}
