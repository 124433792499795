.map-container {
  width: 100%;
  height: 400px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.map-container1 {
  width: 100%;
  padding: var(--dl-space-space-oneandhalfunits);
  max-width: 836px;
}
.map-container2 {
  display: contents;
}

.map-root-class-name1 {
  margin-bottom: var(--dl-space-space-threeunits);
}


