.servizi-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.servizi-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.servizi-text {
  color: rgb(3, 80, 158);
  font-size: 3.2rem;
  font-family: "Merriweather";
  font-weight: 200;
  margin-bottom: var(--dl-space-space-twounits);
}
.servizi-link {
  display: contents;
}
.servizi-component01 {
  text-decoration: none;
}
.servizi-container1 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  position: relative;
  margin-top: var(--dl-space-space-fourunits);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.servizi-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.servizi-text03 {
  color: rgb(3, 80, 158);
  font-size: 2.8rem;
  text-align: center;
  font-family: "Merriweather";
  font-weight: 200;
}
.servizi-text04 {
  max-width: 800px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.servizi-link1 {
  display: contents;
}
.servizi-component06 {
  text-decoration: none;
}
.servizi-image {
  width: 100%;
  height: 700px;
  object-fit: cover;
}
.servizi-gallery {
  width: 100%;
  height: 1000px;
  display: none;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
}
.servizi-container2 {
  flex: 3;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.servizi-container3 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.servizi-container4 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.servizi-container5 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.servizi-container6 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}
.servizi-container7 {
  flex: 2;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media(max-width: 991px) {
  .servizi-text {
    text-align: center;
  }
  .servizi-container1 {
    grid-template-columns: 1fr 1fr;
  }
  .servizi-text04 {
    max-width: 100%;
  }
  .servizi-image {
    height: 600px;
  }
  .servizi-container7 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .servizi-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .servizi-text {
    text-align: center;
  }
  .servizi-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .servizi-text04 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .servizi-image {
    width: 100%;
    height: 450px;
  }
  .servizi-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .servizi-container3 {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .servizi-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .servizi-container1 {
    grid-template-columns: 1fr;
  }
  .servizi-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .servizi-image {
    height: 400px;
  }
  .servizi-gallery {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .servizi-container2 {
    width: auto;
  }
}
