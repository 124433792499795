.footer-footer {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  animation-name: none;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.footer-container {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.footer-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.footer-image {
  width: 298px;
  height: 293px;
  margin-bottom: 10px;
}
.footer-nav {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
}
.footer-navlink {
  margin-left: 0px;
  text-decoration: none;
}
.footer-navlink1 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink2 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink3 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink4 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.footer-navlink5 {
  margin-left: 0px;
  text-decoration: none;
}
.footer-separator1 {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.footer-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-link {
  display: contents;
}
.footer-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.footer-link1 {
  display: contents;
}
.footer-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  text-decoration: none;
}





@media(max-width: 991px) {
  .footer-nav {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 767px) {
  .footer-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .footer-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-image {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-separator1 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-container1 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-text {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .footer-footer {
    padding: var(--dl-space-space-unit);
  }
  .footer-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-separator1 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-container1 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-text {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
