.testimonial-card4-testimonial-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  align-self: stretch;
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.testimonial-card4-icon {
  width: var(--dl-size-size-medium);
  flex-shrink: 0;
  margin-bottom: var(--dl-space-space-halfunit);
}
.testimonial-card4-text {
  color: var(--dl-color-gray-500);
  font-size: 1.3rem;
  line-height: 1.4;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.testimonial-card4-profile {
  width: 100%;
  display: flex;
  margin-top: auto;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: #03509e;
  border-width: 1px;
  flex-direction: row;
  justify-content: flex-start;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.testimonial-card4-container {
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: space-between;
}
.testimonial-card4-text1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0.25rem;
}





@media(max-width: 991px) {
  .testimonial-card4-icon {
    height: var(--dl-size-size-medium);
  }
  .testimonial-card4-profile {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .testimonial-card4-container {
    width: 117px;
    margin-left: 0px;
  }
}
@media(max-width: 479px) {
  .testimonial-card4-testimonial-card {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .testimonial-card4-profile {
    justify-content: center;
  }
}
