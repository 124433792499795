.navigation-links1-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navigation-links1-navlink {
  transition: 0.3s;
}
.navigation-links1-navlink:focus {
  border-bottom: 2px solid;
}
.navigation-links1-navlink:hover {
  border-bottom: 2px solid;
}
.navigation-links1-navlink1 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links1-navlink1:focus {
  border-bottom: 2px solid;
}
.navigation-links1-navlink1:hover {
  border-bottom: 2px solid;
}
.navigation-links1-navlink2 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links1-navlink2:focus {
  border-bottom: 2px solid;
}
.navigation-links1-navlink2:hover {
  border-bottom: 2px solid;
}
.navigation-links1-navlink3 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links1-navlink3:focus {
  border-bottom: 2px solid;
}
.navigation-links1-navlink3:hover {
  border-bottom: 2px solid;
}








@media(max-width: 767px) {
  .navigation-links1-nav {
    align-items: flex-start;
    flex-direction: column;
  }
  .navigation-links1-navlink {
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links1-navlink1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links1-navlink2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links1-navlink3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
}
