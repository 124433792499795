.la-spiaggia-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.la-spiaggia-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.la-spiaggia-text {
  color: #03509e;
  font-size: 3.6rem;
  text-align: center;
  font-family: "Merriweather";
  font-weight: 200;
}
.la-spiaggia-text01 {
  max-width: 700px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-family: "Inter";
  font-weight: 300;
  margin-bottom: var(--dl-space-space-twounits);
}
.la-spiaggia-gallery {
  width: 100%;
  height: 1000px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
}
.la-spiaggia-container1 {
  flex: 3;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.la-spiaggia-container2 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.la-spiaggia-container3 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.la-spiaggia-container4 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.la-spiaggia-container5 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}
.la-spiaggia-container6 {
  flex: 2;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.la-spiaggia-banner1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.la-spiaggia-text13 {
  color: #03509e;
  font-size: 2.8rem;
  text-align: center;
  font-family: "Merriweather";
}
.la-spiaggia-text14 {
  max-width: 650px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-weight: 300;
  margin-bottom: var(--dl-space-space-twounits);
}
.la-spiaggia-link {
  display: contents;
}
.la-spiaggia-component5 {
  text-decoration: none;
}
.la-spiaggia-banner2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.la-spiaggia-text27 {
  color: #03509e;
  font-size: 2.8rem;
  text-align: center;
  font-family: "Merriweather";
}
@media(max-width: 991px) {
  .la-spiaggia-text01 {
    max-width: 100%;
  }
  .la-spiaggia-container6 {
    width: 100%;
  }
  .la-spiaggia-text14 {
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .la-spiaggia-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .la-spiaggia-text01 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .la-spiaggia-gallery {
    height: auto;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .la-spiaggia-container2 {
    flex-direction: column;
  }
  .la-spiaggia-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .la-spiaggia-text14 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .la-spiaggia-banner2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .la-spiaggia-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .la-spiaggia-gallery {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .la-spiaggia-container1 {
    width: auto;
  }
  .la-spiaggia-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .la-spiaggia-banner2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
