.btn-outline-container {
  display: flex;
  position: relative;
}
.btn-outline-register {
  font-size: 20px;
  font-family: "Merriweather";
  border-color: #03509e;
  border-width: 2px;
  border-radius: 0px;
  text-decoration: none;
}
.btn-outline-text {
  color: rgb(3, 80, 158);
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
