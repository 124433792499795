.btn-container {
  display: flex;
  position: static!important;
}
.btn-button {
  color: var(--dl-color-gray-white);
  position: static!important;
  font-size: 1rem;
  box-shadow: 0px 0px 43px -3px rgba(66, 68, 90, 0.3);
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-family: "Inter";
  font-weight: 500;
  padding-top: var(--dl-space-space-twounits);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 0;
  padding-right: var(--dl-space-space-threeunits);
  letter-spacing: 0.3rem;
  padding-bottom: var(--dl-space-space-twounits);
  text-transform: uppercase;
  background-color: #03509e;
}
.btn-button:hover {
  cursor: pointer;
  transform: scale(1.02);
}


.btn-root-class-name2 {
  align-self: center;
}


.btn-root-class-name5 {
  left: 824px;
  bottom: 164px;
  position: 0px;
  align-self: center;
}

.btn-root-class-name7 {
  left: 472px;
  bottom: 164px;
  position: 0px;
  align-self: center;
}
.btn-root-class-name8 {
  left: 472px;
  bottom: 164px;
  position: 0px;
  align-self: center;
}
@media(max-width: 479px) {
  .btn-button {
    font-family: Montserrat;
  }
}
