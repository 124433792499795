.feature-card3-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.feature-card3-image {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.feature-card3-text {
  color: #03509e;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: "Merriweather";
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card3-text1 {
  color: var(--dl-color-gray-700);
  text-align: center;
}




